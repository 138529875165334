.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #E30432;
  font-size: 12px;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 12px 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.08em;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #ca042c;
}
.button:active {
  background-color: #980321;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #E30432;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.625;
}
.unit caption {
  display: none;
}
.flag {
  background: #E30432;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.area {
  float: left;
  width: 100%;
}
.unit {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}
@media (max-width: 1023px) {
  .unit {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .unit {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .unit {
    margin-bottom: 10px;
  }
}
.unit .head,
.unit .body,
.unit .foot {
  float: left;
  width: 100%;
}
.unit.fold div.less,
.unit.fold div.more {
  float: left;
  width: 100%;
}
.unit.fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
@media (max-width: 1023px) {
  .part {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .part {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .part {
    margin-bottom: 10px;
  }
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
}
#expo .cb-image-figure {
  height: 100%;
}
#expo .cb-image-container {
  height: 100% !important;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  display: inline-block;
  padding-left: 26px;
  background-size: 16px 16px;
  background-position: 0 5px;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-download-black.svg);
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 20px;
  list-style-position: outside;
}
#expo {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.moodgrid {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.c10 .moodgrid {
  display: none;
}
.cb-layout3 .moodgrid {
  display: none !important;
}
.moodlines {
  position: absolute;
  border-color: #fff;
  border-style: solid;
  box-sizing: border-box;
}
.moodlines--horz {
  top: 33.33333333%;
  left: 0;
  height: 33.33333333%;
  width: 100%;
  border-width: 2px 0;
}
.moodlines--vert {
  top: 0;
  height: 100%;
  width: 16.66666667%;
  border-width: 0 1px;
}
.moodlines--vert1 {
  left: 16.66666667%;
  border-width: 0 1px 0 2px;
}
.moodlines--vert2 {
  left: 33.33333333%;
}
.moodlines--vert3 {
  left: 50%;
}
.moodlines--vert4 {
  left: 66.66666667%;
  border-width: 0 2px 0 1px;
}
@media (max-width: 1023px) {
  .moodlines--vert {
    width: 25%;
  }
  .moodlines--vert1 {
    left: 25%;
  }
  .moodlines--vert2 {
    left: 50%;
    border-width: 0 2px 0 1px;
  }
  .moodlines--vert3,
  .moodlines--vert4 {
    display: none;
  }
}
@media (max-width: 767px) {
  .moodlines--vert {
    width: 33.33333333%;
  }
  .moodlines--vert1 {
    left: 33.33333333%;
    border-width: 0 2px;
  }
  .moodlines--vert2 {
    display: none;
  }
}
.moodhome {
  position: absolute;
  z-index: 4;
}
.c9 .moodhome,
.c10 .moodhome {
  display: none;
}
.moodhome .moodlogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
}
.moodhome .moodlogo.moodlogo--red {
  display: none;
}
.c5 .moodhome .moodlogo.moodlogo--white,
.c6 .moodhome .moodlogo.moodlogo--white,
.c7 .moodhome .moodlogo.moodlogo--white,
.c8 .moodhome .moodlogo.moodlogo--white {
  display: none;
}
.c5 .moodhome .moodlogo.moodlogo--red,
.c6 .moodhome .moodlogo.moodlogo--red,
.c7 .moodhome .moodlogo.moodlogo--red,
.c8 .moodhome .moodlogo.moodlogo--red {
  display: block;
}
.cb-layout3 .moodhome {
  display: none !important;
}
.c1 .moodhome {
  left: 0;
  top: 0;
  width: 16.66666667%;
  height: 66.66666667%;
  background-image: linear-gradient(to bottom, #E30432 50%, transparent);
}
.c1 .moodhome .moodlogo {
  width: 100%;
  max-height: 80%;
  height: auto;
}
.c2 .moodhome {
  right: 0;
  top: 0;
  width: 16.66666667%;
  height: 66.66666667%;
  background-image: linear-gradient(to bottom, #E30432 50%, transparent);
}
.c2 .moodhome .moodlogo {
  width: 100%;
  max-height: 80%;
  height: auto;
}
.c3 .moodhome {
  left: 0;
  top: calc((100% / 3) + 2px);
  width: 33.33333333%;
  height: calc((100% / 3) - 4px);
  background-image: linear-gradient(to right, #E30432 50%, transparent);
}
.c3 .moodhome .moodlogo {
  width: auto;
  height: 100%;
  max-width: 80%;
}
.c4 .moodhome {
  right: 0;
  top: calc((100% / 3) + 2px);
  width: 33.33333333%;
  height: calc((100% / 3) - 4px);
  background-image: linear-gradient(to left, #E30432 50%, transparent);
}
.c4 .moodhome .moodlogo {
  width: auto;
  height: 100%;
  max-width: 80%;
}
.c5 .moodhome {
  left: 0;
  top: 0;
  width: 16.66666667%;
  height: 66.66666667%;
  background-image: linear-gradient(to bottom, #fff 50%, transparent);
}
.c5 .moodhome .moodlogo {
  width: 100%;
  max-height: 80%;
  height: auto;
}
.c6 .moodhome {
  right: 0;
  top: 0;
  width: 16.66666667%;
  height: 66.66666667%;
  background-image: linear-gradient(to bottom, #fff 50%, transparent);
}
.c6 .moodhome .moodlogo {
  width: 100%;
  max-height: 80%;
  height: auto;
}
.c7 .moodhome {
  left: 0;
  top: calc((100% / 3) + 2px);
  width: 33.33333333%;
  height: calc((100% / 3) - 4px);
  background-image: linear-gradient(to right, #fff 50%, transparent);
}
.c7 .moodhome .moodlogo {
  width: auto;
  height: 100%;
  max-width: 80%;
}
.c8 .moodhome {
  right: 0;
  top: calc((100% / 3) + 2px);
  width: 33.33333333%;
  height: calc((100% / 3) - 4px);
  background-image: linear-gradient(to left, #fff 50%, transparent);
}
.c8 .moodhome .moodlogo {
  width: auto;
  height: 100%;
  max-width: 80%;
}
@media (max-width: 1023px) {
  .c1 .moodhome,
  .c2 .moodhome,
  .c5 .moodhome,
  .c6 .moodhome {
    width: 25%;
  }
  .c3 .moodhome,
  .c4 .moodhome,
  .c7 .moodhome,
  .c8 .moodhome {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .c1 .moodhome,
  .c2 .moodhome,
  .c5 .moodhome,
  .c6 .moodhome {
    width: 33.33333333%;
  }
  .c3 .moodhome,
  .c4 .moodhome,
  .c7 .moodhome,
  .c8 .moodhome {
    width: 66.66666667%;
  }
}
#expo div.link {
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  bottom: 30px;
  z-index: 10;
  width: 33px;
  height: 60px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  display: none;
}
@media (max-width: 767px) {
  #expo a.link {
    width: 22px;
    height: 40px;
  }
}
#expo a.link.prev {
  left: 30px;
}
@media (max-width: 767px) {
  #expo a.link.prev {
    left: 20px;
  }
}
#expo a.link.next {
  right: 30px;
  background-image: url(/images/arrow-right-white.svg);
}
@media (max-width: 767px) {
  #expo a.link.next {
    right: 20px;
  }
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
  display: none;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #E30432;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.42857143;
  color: #000000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #E30432;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #E30432;
  font-size: 16px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  background-color: #E30432;
  font-size: 12px;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 12px 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.08em;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  line-height: 1.42857143;
}
#disp .foot input:hover,
#disp .foot a:hover,
#disp .foot input:focus,
#disp .foot a:focus {
  color: #fff;
  background-color: #ca042c;
}
#disp .foot input:active,
#disp .foot a:active {
  background-color: #980321;
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'Roboto', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 50px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  min-height: 50px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.sidecontent .unit.form input.text,
.sidecontent .unit.form textarea {
  background: #f0f0f0;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 26px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 12px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  background: #fff;
  height: 50px;
  border: 1px solid #000000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #E30432;
  font-size: 12px;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 12px 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.08em;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .unit.form input.submit {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form input.submit {
    margin-bottom: 10px;
  }
}
.unit.form input.submit:hover,
.unit.form input.submit:focus {
  color: #fff;
  background-color: #ca042c;
}
.unit.form input.submit:active {
  background-color: #980321;
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 1023px) {
  .unit.form div.cb-form-sent {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .unit.form div.cb-form-sent {
    margin-bottom: 10px;
  }
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  background-color: #E30432;
  font-size: 12px;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 12px 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.08em;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
.two-step-verification-container a:hover,
.two-step-verification-container a:focus {
  color: #fff;
  background-color: #ca042c;
}
.two-step-verification-container a:active {
  background-color: #980321;
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.08604954vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 40px;
  margin: 15px 0 15px 20px;
}
.logo {
  width: auto;
  height: 100%;
}
.fr .logo {
  content: url(/images/logo-fr-2024-04-30.svg);
}
#head {
  float: left;
  width: 100%;
  padding-bottom: 93px;
}
.cb-layout2 #head {
  margin-top: 200px;
  padding-bottom: 0;
}
@media (max-width: 1023px) {
  .cb-layout2 #head {
    margin-top: 140px;
  }
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #f0f0f0;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.625;
  color: #000000;
  overflow: hidden;
  hyphens: auto;
  font-weight: 300;
}
.cb-layout3 .wrapper {
  background-color: transparent;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 90%;
  width: 1200px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--multimood {
  height: 100vh;
  box-sizing: border-box;
  border-top: 70px solid #fff;
}
.cb-layout2 .section--multimood {
  display: none;
}
.cb-layout3 .section--multimood {
  background-color: #E30432;
}
.section--header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
@media (max-width: 1023px) {
  .section--header {
    display: block;
  }
}
.section--footer {
  margin-top: 60px;
  background-color: #dadada;
  background-color: #E30432;
  color: #fff;
  font-size: 16px;
  line-height: 1.625;
}
@media (max-width: 1023px) {
  .section--footer {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .section--footer {
    margin-top: 40px;
  }
}
.section--footer a {
  color: #fff;
  text-decoration: none;
}
.section--footer a:hover,
.section--footer a:focus {
  color: #000;
}
#view.side--empty .section--footer {
  margin-top: 0;
}
#headerlinks {
  position: absolute;
  right: 20px;
  top: 16px;
  z-index: 2;
  display: flex;
  align-items: center;
}
@media (max-width: 1023px) {
  #headerlinks {
    right: 80px;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width: 767px) {
  #headerlinks {
    display: none;
  }
}
#headerlinks .meta {
  float: left;
  margin-right: 26px;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
#headerlinks div.sub1 {
  float: left;
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
}
#headerlinks div.sub1 > .item {
  float: left;
}
#headerlinks div.sub1 > .item:after {
  content: '|';
  float: left;
  margin: 0 5px;
}
#headerlinks div.sub1 > .item.exit:after {
  display: none;
}
#headerlinks div.sub1 > .item > .menu {
  float: left;
  text-transform: uppercase;
  text-decoration: none;
}
#headerlinks div.sub1 > .item > .menu.path {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
#headerlinks div.sub1 > .item > .menu.path:hover,
#headerlinks div.sub1 > .item > .menu.path:focus {
  color: #000000;
}
.navigation,
.intraNavigation {
  float: left;
  margin-left: 80px;
  margin-top: 10px;
}
@media (max-width: 1869px) {
  .navigation,
  .intraNavigation {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media (max-width: 1023px) {
  .navigation,
  .intraNavigation {
    display: none;
  }
}
.moodcontent {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 33.33333333%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}
.cb-layout3 .moodcontent {
  min-height: 0;
  background-color: transparent;
}
.downlink {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  width: 60px;
  height: 33px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down-white.svg);
  cursor: pointer;
}
@media (max-width: 767px) {
  .downlink {
    bottom: 80px;
  }
}
.sidecontent {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 8px;
}
#view.side--empty .sidecontent {
  display: none;
}
.footcontent {
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.vcard {
  float: left;
  margin: 60px 0;
}
.vcard .org {
  text-transform: uppercase;
}
.foottitle {
  display: block;
  padding-bottom: 20px;
  font-size: 20px;
  line-height: 1.5;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
.footlinks {
  float: left;
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
#social {
  float: left;
  margin-bottom: 30px;
}
#social .meta {
  float: left;
  width: 40px;
  height: 40px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/social-facebook-white.svg);
  margin-right: 20px;
}
#social .meta:last-child {
  margin-right: 0;
}
#social .meta:hover,
#social .meta:focus {
  background-image: url(/images/social-facebook-black.svg);
}
#social .meta.service_linkedin {
  background-image: url(/images/social-linkedin-white.svg);
}
#social .meta.service_linkedin:hover,
#social .meta.service_linkedin:focus {
  background-image: url(/images/social-linkedin-black.svg);
}
#social .meta.service_youtube {
  background-image: url(/images/social-youtube-white.svg);
}
#social .meta.service_youtube:hover,
#social .meta.service_youtube:focus {
  background-image: url(/images/social-youtube-black.svg);
}
#social .meta.service_xing {
  background-image: url(/images/social-xing-white.svg);
}
#social .meta.service_xing:hover,
#social .meta.service_xing:focus {
  background-image: url(/images/social-xing-black.svg);
}
#social .meta.service_instagram {
  background-image: url(/images/social-instagram-white.svg);
}
#social .meta.service_instagram:hover,
#social .meta.service_instagram:focus {
  background-image: url(/images/social-instagram-black.svg);
}
#services {
  float: left;
  font-size: 13px;
  line-height: 1.53846154;
}
#services:before {
  content: '© Rothgruppe |';
  display: inline-block;
  margin-right: 5px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  #services:before {
    content: '© Rothgruppe';
    display: block;
    float: left;
    font-size: 13px;
    line-height: 1.23076923;
    margin: 0 0 10px 0;
  }
}
#services .meta {
  letter-spacing: 0.03em;
  margin: 0 2px;
}
#services .meta:first-child {
  margin-left: 0;
}
#services .meta:last-child {
  margin-right: 0;
}
#cb-cookie-warning {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2000;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(160, 160, 160, 0.4);
  display: flex;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 1.28571429;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
.cb-cookie-warning--container {
  float: left;
  width: 1200px;
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
@media (max-width: 767px) {
  .cb-cookie-warning--container {
    flex-direction: column;
  }
}
.cb-cookie-warning--text {
  float: left;
}
.cb-cookie-warning--actions {
  float: left;
  margin-left: 40px;
  flex-shrink: 0;
}
@media (max-width: 1023px) {
  .cb-cookie-warning--actions {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  .cb-cookie-warning--actions {
    margin-left: 0;
    margin-top: 10px;
  }
}
.cb-cookie-warning__button {
  background-color: #E30432;
  padding: 8px 16px;
  color: #fff;
  margin-right: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.cb-cookie-warning__button:last-child {
  margin-right: 0;
}
.cb-cookie-warning__button:hover,
.cb-cookie-warning__button:focus {
  background-color: #fb1f4c;
}
@media (max-width: 1023px) {
  .cb-cookie-warning__button {
    margin-right: 10px;
  }
}
#cb-cookie-warning__button--decline {
  display: none;
}
.landingPage {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
}
.cb-layout3 .landingPage {
  display: block;
}
.landingpage__worker {
  position: absolute;
  right: 40px;
  bottom: 0;
  width: 800px;
  height: auto;
  z-index: 3;
}
.landingpage__content {
  float: left;
  position: relative;
  z-index: 4;
  width: 1120px;
  margin-left: 120px;
  margin-top: 120px;
  max-width: calc(100% - 240px);
}
.landingPage .area {
  margin-top: -40px;
  margin-bottom: -40px;
}
.landingPage .area .unit {
  margin-top: 0;
  margin-bottom: 0;
}
.landingPage .area .unit .part {
  margin-top: 40px;
  margin-bottom: 40px;
}
.landingPage .area .unit .part.link .open {
  background-color: #fff;
  color: #E30432;
  font-size: 26px;
  line-height: 1.38461538;
}
.landingPage .area .unit .part.link .open:hover,
.landingPage .area .unit .part.link .open:focus {
  background-color: #fce6eb;
}
.landingPage .area .unit .part.link .open:active {
  background-color: #f9cdd6;
}
.cbdModule--moodBatch {
  position: absolute;
  right: 50%;
  margin-right: -600px;
  bottom: -30px;
  z-index: 5;
}
#view .cbdModule--moodBatch.is-empty {
  display: none;
}
@media (max-width: 1334px) {
  .cbdModule--moodBatch {
    right: 5%;
    margin-right: 0;
  }
}
@media (max-width: 1023px) {
  .cbdModule--moodBatch {
    bottom: -20px;
  }
}
.moodBatch__link {
  float: left;
  width: 200px;
  height: 200px;
  border-radius: 10000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transform: rotate(-20deg);
  box-shadow: 6px 6px 10px rgba(120, 120, 120, 0.6);
  font-size: 36px;
  line-height: 1.11111111;
  color: #E30432;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  padding: 20px;
  transition: all 0.3s;
}
.moodBatch__link:hover {
  transform: rotate(0);
}
@media (max-width: 1023px) {
  .moodBatch__link {
    font-size: 20px;
    line-height: 1.3;
    width: 150px;
    height: 150px;
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .moodBatch__link {
    font-size: 14px;
    line-height: 1.28571429;
    width: 100px;
    height: 100px;
  }
}
a {
  color: #000000;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #E30432;
}
h1 {
  text-align: center;
  color: #fff;
  font-weight: 300;
  font-size: 60px;
  line-height: 0.9;
  letter-spacing: 0.03em;
}
.cb-layout2 h1 {
  color: #E30432;
  text-align: left;
  font-size: 40px;
  line-height: 1.25;
}
h2 {
  font-size: 40px;
  line-height: 1.25;
  color: #E30432;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
.landingPage .area h2 {
  color: #fff;
  font-size: 100px;
  line-height: 1.2;
  font-weight: normal;
}
.landingPage .area h2 strong {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
h3 {
  font-size: 32px;
  line-height: 1.3125;
  color: #E30432;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
h4 {
  font-size: 26px;
  line-height: 1.38461538;
  color: #E30432;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
h5 {
  font-size: 20px;
  line-height: 1.5;
  color: #E30432;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
.loud {
  font-size: 26px;
  line-height: 1.38461538;
}
#view.side--empty .section--side .sidecontent,
#view.farwest--empty .section--farwest .sidecontent {
  display: none;
}
.section--one .area {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1023px) {
  .section--one .area {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .section--one .area {
    margin-top: 20px;
  }
}
@media (max-width: 1023px) {
  .section--one .area {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .section--one .area {
    margin-bottom: 20px;
  }
}
.section--two .area {
  margin-top: -30px;
  margin-bottom: -30px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1023px) {
  .section--two .area {
    margin-top: -20px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-top: -20px;
  }
}
@media (max-width: 1023px) {
  .section--two .area {
    margin-bottom: -20px;
  }
}
@media (max-width: 767px) {
  .section--two .area {
    margin-bottom: -20px;
  }
}
.section--two .area .pure.slim .body {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.section--two .area .pure.slim .part:last-child {
  margin-top: auto;
}
.section--two .area .seam .body {
  display: flex;
  flex-wrap: wrap;
}
.section--two .area .seam .part {
  margin: 4px 0 !important;
  box-sizing: border-box;
}
.section--two .area .seam .pict .cb-image-figure {
  overflow: hidden;
  position: relative;
}
.section--two .area .seam .pict .cb-image-figure .cb-image-caption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  padding: 20px 66px 20px 20px;
  color: #fff;
  transition: all 0.4s;
  transform: translateY(100%);
}
@media (max-width: 1023px) {
  .section--two .area .seam .pict .cb-image-figure .cb-image-caption {
    position: static;
    transform: translateY(0);
    padding: 10px 14px;
    background-color: #000000;
    font-size: 14px;
    line-height: 1.42857143;
  }
}
.section--two .area .seam .pict:hover .cb-image-figure .cb-image-caption {
  transform: translateY(0);
}
.unit.fold .link {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  color: #E30432;
  text-decoration: none;
}
div.link .open {
  display: inline-block;
  background-color: #E30432;
  font-size: 12px;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 12px 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.08em;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
div.link .open:hover,
div.link .open:focus {
  color: #fff;
  background-color: #ca042c;
}
div.link .open:active {
  background-color: #980321;
}
@media (max-width: 1023px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
  .section {
    transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
  }
  body.cb-toggle-target-active .section {
    transform: translateY(200px);
  }
  body.cb-toggle-target-active .section.section--header {
    transform: none;
  }
  .navi {
    display: none;
  }
}
.togglenavigation {
  position: relative;
  float: right;
  margin: 23px 20px 23px 0;
  width: 30px;
  height: 24px;
}
.tline {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 4px;
  background-color: #E30432;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.tline--2,
.tline--3 {
  top: 50%;
  transform: translateY(-50%);
}
.tline--4 {
  top: unset;
  bottom: 0;
}
body.cb-toggle-target-active .tline--1 {
  opacity: 0;
  transform: translateY(-10px);
}
body.cb-toggle-target-active .tline--2 {
  transform: rotate(-45deg);
}
body.cb-toggle-target-active .tline--3 {
  transform: rotate(45deg);
}
body.cb-toggle-target-active .tline--4 {
  opacity: 0;
  transform: translateY(10px);
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  overflow-y: scroll;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navigation .service_brandschutzportal {
  float: left;
  background-color: #E30432;
  font-size: 12px;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 12px 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.08em;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
.mobile-navigation .service_brandschutzportal:hover,
.mobile-navigation .service_brandschutzportal:focus {
  color: #fff;
  background-color: #ca042c;
}
.mobile-navigation .service_brandschutzportal:active {
  background-color: #980321;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: block;
  position: relative;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #000000;
  text-decoration: none;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: #E30432;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
.mobile-navigation div.sub1 {
  float: left;
  width: auto;
  margin: 20px 0 60px;
}
.mobile-navigation div.sub1 > .item {
  width: auto;
  float: left;
}
.mobile-navigation div.sub1 > .item:after {
  content: '|';
  display: inline-block;
  margin: 0 5px;
}
.mobile-navigation div.sub1 > .item.exit:after {
  display: none;
}
.mobile-navigation div.sub1 > .item > .menu {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
}
.mobile-navigation div.sub1 > .item > .menu.path {
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
.mobile-navigation div.sub1 > .item > .menu.path:hover,
.mobile-navigation div.sub1 > .item > .menu.path:focus {
  color: #000000;
}
.mobile-navigation div.sub2 {
  box-sizing: border-box;
  padding: 0 20px;
  margin-top: 120px;
  margin-bottom: 60px;
  max-width: 600px;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub2 {
    margin-top: 100px;
  }
}
.mobile-navigation div.sub2 > .item {
  margin: 10px 0;
}
.mobile-navigation div.sub2 > .item.init {
  margin-top: 0;
}
.mobile-navigation div.sub2 > .item.exit {
  margin-bottom: 0;
}
.mobile-navigation div.sub2 > .item > .menu {
  font-size: 30px;
  line-height: 1.4;
  padding-right: 30px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub2 > .item > .menu {
    font-size: 24px;
    line-height: 1.41666667;
  }
}
.mobile-navigation div.sub3 {
  box-sizing: border-box;
  padding-left: 20px;
}
.mobile-navigation div.sub3 > .item {
  margin: 4px 0;
}
.mobile-navigation div.sub3 > .item.init {
  margin-top: 10px;
}
.mobile-navigation div.sub3 > .item.exit {
  margin-bottom: 10px;
}
.mobile-navigation div.sub3 > .item > .menu {
  font-size: 22px;
  line-height: 1.45454545;
}
@media (max-width: 767px) {
  .mobile-navigation div.sub3 > .item > .menu {
    font-size: 18px;
    line-height: 1.55555556;
  }
}
.mobile-navigation div.sub4 {
  box-sizing: border-box;
  padding-left: 20px;
}
.mobile-navigation div.sub4 > .item {
  margin: 4px 0;
}
.mobile-navigation div.sub4 > .item.init {
  margin-top: 10px;
}
.mobile-navigation div.sub4 > .item.exit {
  margin-bottom: 10px;
}
.mobile-navigation div.sub3,
.mobile-navigation div.sub4 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub3 > .item,
.mobile-navigation div.sub4 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 30px;
  height: 42px;
  background-size: 20px 11px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down-gray.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
@media (max-width: 767px) {
  .mobile-navigation .cb-toggle {
    height: 34px;
  }
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.mobile-navigation div.sub3 .cb-toggle {
  height: 28px;
}
.part.auth form {
  float: left;
  width: 100%;
}
.part.auth .body,
.part.auth .foot {
  width: 100% !important;
  margin: 0 !important;
}
.part.auth fieldset {
  float: left;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 1023px) {
  .part.auth fieldset {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .part.auth fieldset {
    margin-top: 10px;
  }
}
@media (max-width: 1023px) {
  .part.auth fieldset {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .part.auth fieldset {
    margin-bottom: 10px;
  }
}
.part.auth fieldset:first-child {
  margin-top: 0;
}
.part.auth label.name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .part.auth label.name {
    width: 30%;
    min-height: 50px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.part.auth input.text,
.part.auth input.password {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  min-height: 50px;
  padding: 5px 10px;
}
.part.auth input.text.fail,
.part.auth input.password.fail {
  border: 2px solid #ae2121;
}
.part.auth input.text.fade,
.part.auth input.password.fade {
  opacity: 0.3;
}
@media (min-width: 768px) {
  .part.auth input.text,
  .part.auth input.password {
    float: right;
    width: 66%;
  }
}
.auth-login-button {
  float: right;
  margin-top: 10px;
}
@media (max-width: 1023px) {
  .auth-login-button {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .auth-login-button {
    margin-top: 10px;
  }
}
.part.auth input.submit {
  float: left;
  background-color: #E30432;
  font-size: 12px;
  line-height: 1.33333333;
  cursor: pointer;
  color: #fff;
  padding: 12px 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.08em;
  -webkit-appearance: none;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
.part.auth input.submit:hover,
.part.auth input.submit:focus {
  color: #fff;
  background-color: #ca042c;
}
.part.auth input.submit:active {
  background-color: #980321;
}
.cb-album {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.cb-album .body-mobile {
  display: none !important;
}
.cb-album h2 {
  display: none;
}
.cb-album .body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cb-album .part {
  width: 100% !important;
  margin: 0 !important;
}
.cb-album .part.desc {
  display: none;
}
#root.ie11 .cb-album img {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-album.cb-album .scan {
  margin-top: 40px !important;
}
.cb-album.cb-album .scan:first-child {
  margin-top: 0 !important;
  margin-bottom: 40px !important;
}
.cb-album .scan table,
.cb-album .scan tbody,
.cb-album .scan tr {
  float: left;
  display: block;
  width: 100%;
}
.cb-matrix .body {
  margin-top: -0.5%;
  margin-bottom: -0.5%;
}
.cb-matrix .part {
  margin-top: 0.5% !important;
  margin-bottom: 0.5% !important;
}
.cb-matrix .part.foto {
  height: auto !important;
  box-sizing: border-box;
}
.cb-matrix .part.foto a.foto,
.cb-matrix .part.foto span.foto {
  display: block;
  height: 0;
  padding-bottom: 60%;
  position: relative;
  overflow: hidden;
}
.cb-matrix .part.foto img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-matrix .part.foto a.foto.zoom img {
  transform: scale(1.1);
}
.cb-matrix.cb-matrix-columns-1 .part.foto {
  width: 100% !important;
}
.cb-matrix.cb-matrix-columns-2 .part.foto {
  width: 49.5% !important;
}
.cb-matrix.cb-matrix-columns-3 .part.foto {
  width: 32.66666667% !important;
}
.cb-matrix.cb-matrix-columns-4 .part.foto {
  width: 24.25% !important;
}
.cb-matrix.cb-matrix-columns-5 .part.foto {
  width: 19.2% !important;
}
.cb-matrix.cb-matrix-columns-6 .part.foto {
  width: 15.83333333% !important;
}
.cb-matrix.cb-matrix-columns-7 .part.foto {
  width: 13.42857143% !important;
}
.cb-matrix.cb-matrix-columns-8 .part.foto {
  width: 11.625% !important;
}
.cb-matrix .scan tr {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.cb-matrix .scan td {
  float: left;
  display: block;
  margin: 0 10px;
  font-size: 16px;
  line-height: 1.25;
}
.cb-matrix .scan td:first-child {
  margin-left: 0;
}
.cb-matrix .scan td:last-child {
  margin-right: 0;
}
.cb-matrix .scan td.prev,
.cb-matrix .scan td.next {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 20px;
  height: 20px;
}
.cb-matrix .scan td.prev a,
.cb-matrix .scan td.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-arrow-left-gray.svg);
}
.cb-matrix .scan td.prev:empty,
.cb-matrix .scan td.next:empty {
  opacity: 0.5;
  pointer-events: none;
}
.cb-matrix .scan td.next a {
  background-image: url(/images/cb-arrow-right-gray.svg);
}
.cb-matrix .scan td a,
.cb-matrix .scan td span {
  display: block;
  width: 26px;
  height: 26px;
  color: #000000;
  border-radius: 10000px;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
}
.cb-matrix .scan td .same {
  color: #fff;
  background-color: #E30432;
}
.cb-strips .part.foto {
  height: auto !important;
  max-height: 1000000000px !important;
}
.cb-strips-images {
  float: left;
  width: 100%;
  height: 0 !important;
  padding-bottom: 56.25%;
  position: relative;
}
.cb-strips-images a {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.96);
  z-index: 2;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1), transform 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-images a.is-active {
  z-index: 3;
  pointer-events: auto;
  opacity: 1;
  transform: scale(1);
}
.cb-strips-images img {
  opacity: 1 !important;
  display: block !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
.cb-strips .scan tbody > tr {
  float: left;
  width: 100%;
  display: flex;
}
.cb-strips .scan tbody > tr > td {
  float: left;
  width: 100%;
  display: block;
}
.cb-strips .scan td.prev,
.cb-strips .scan td.next {
  margin-right: 1.25%;
  width: 6.25%;
}
.cb-strips .scan td.next {
  margin-right: 0;
  margin-left: 1.25%;
}
.cb-strips .scan div.prev,
.cb-strips .scan div.next {
  float: left;
  width: 100%;
  height: 100%;
}
.cb-strips .scan div.prev a,
.cb-strips .scan div.next a {
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
  transition: background 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips .scan div.prev a:hover,
.cb-strips .scan div.next a:hover {
  background-position: 40% 50%;
}
.cb-strips .scan div.next a {
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.cb-strips .scan div.next a:hover {
  background-position: 60% 50%;
}
.cb-strips .scan td.cb-strips-thumbs {
  width: 85%;
}
.cb-strips-container {
  float: left;
  width: 100%;
}
.cb-strips-container .body {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.cb-strips-container .part.foto {
  position: relative;
}
.cb-strips-container .part.foto a.foto,
.cb-strips-container .part.foto span.foto {
  float: left;
  width: 100%;
  display: block;
  height: 0;
  position: relative;
  padding-bottom: 60%;
  opacity: 0.4;
  transition: opacity 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.cb-strips-container .part.foto a.foto:hover,
.cb-strips-container .part.foto span.foto:hover,
.cb-strips-container .part.foto a.foto.is-active,
.cb-strips-container .part.foto span.foto.is-active {
  opacity: 1;
}
.cb-strips-container .part.foto a.foto .cb-zoom-indicator,
.cb-strips-container .part.foto span.foto .cb-zoom-indicator {
  display: none;
}
.cb-strips-container .part.foto img {
  opacity: 1 !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-strips-container .part.foto a.foto:hover img {
  transform: scale(1.2);
}
.cb-strips-4 .cb-strips-container .part.foto {
  width: 23.95833333% !important;
}
.cb-strips-6 .cb-strips-container .part.foto {
  width: 15.50925926% !important;
}
.cb-strips-8 .cb-strips-container .part.foto {
  width: 11.28472222% !important;
}
.cb-strips-10 .cb-strips-container .part.foto {
  width: 8.75% !important;
}
.cb-slideshow-images {
  float: left;
  width: 100%;
}
.cb-slideshow .part.foto,
.cb-slideshow .part.crossslideContainer {
  max-height: 1000000000px !important;
  max-width: 100% !important;
  height: 0 !important;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
}
.cb-slideshow a.foto {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 1;
  transition: opacity 1s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-slideshow a.foto.is-activated {
  z-index: 2;
  pointer-events: auto;
  opacity: 1;
}
.cb-slideshow img {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  border: none !important;
  z-index: 1;
}
.cb-slideshow img.is-activated {
  z-index: 2;
}
#view .cb-slideshow.cb-manual img {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-manual a.foto.is-activated img {
  transform: scale(1);
}
#view .cb-slideshow.cb-fading img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: scale(1.05);
}
#view .cb-slideshow.cb-fading img.is-activated,
#view .cb-slideshow.cb-fading .cb-image-figure.is-activated img {
  opacity: 1 !important;
  transform: scale(1);
}
#view .cb-slideshow.cb-sliding img {
  opacity: 0 !important;
  width: 110% !important;
  max-width: 110% !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowSlide;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-sliding img.is-activated,
#view .cb-slideshow.cb-sliding .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowSlide {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -10%;
  }
  100% {
    margin-left: 0;
  }
}
#view .cb-slideshow.cb-moving img {
  opacity: 0 !important;
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  animation-name: SlideshowMove;
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
#view .cb-slideshow.cb-moving img.is-activated,
#view .cb-slideshow.cb-moving .cb-image-figure.is-activated img {
  opacity: 1 !important;
}
@keyframes SlideshowMove {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.cb-slideshow .head {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 4;
  height: 0;
  width: 100%;
}
.cb-slideshow .ctrl {
  float: left;
  width: 100%;
}
.cb-slideshow .ctrl a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  width: 50px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.6);
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-slideshow .ctrl a:hover {
  width: 70px;
}
.cb-slideshow .ctrl a.fade {
  opacity: 0.4;
  cursor: default;
  width: 50px;
}
.cb-slideshow .ctrl .next a {
  left: unset;
  right: 0;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.crossslide {
  float: left;
  width: 100%;
  max-width: 100% !important;
  position: static !important;
  padding: 0 !important;
}
@media (max-width: 767px) {
  .footcontent {
    display: block;
  }
  .vcard {
    width: 100%;
    margin: 40px 0 20px;
  }
  .footlinks {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0 40px;
  }
  #services {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0;
  }
  #services .meta {
    font-size: 13px;
    line-height: 1.23076923;
    margin: 5px 0;
  }
  #services .meta:first-child {
    margin-top: 0;
  }
  #services .meta:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 1919px) {
  .landingpage__content {
    margin-left: 60px;
    margin-top: 120px;
    width: 900px;
    max-width: calc(100% - 120px);
  }
  .landingpage__worker {
    width: 600px;
  }
  .landingPage .area h2 {
    font-size: 78px;
  }
  .landingPage .area .unit .part.link .open {
    font-size: 20px;
  }
}
@media (max-width: 1023px) {
  .landingpage__worker {
    display: none;
  }
  .landingPage .area h2 {
    font-size: 58px;
  }
  .landingPage .area .unit .part.link .open {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .landingpage__content {
    margin-top: 60px;
    margin-left: 30px;
    max-width: calc(100% - 60px);
  }
  .landingPage .area h2 {
    font-size: 34px;
  }
  .landingPage .area {
    margin-top: -20px;
    margin-bottom: -20px;
  }
  .landingPage .area .unit .part {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .landingPage .area .unit .part.link .open {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 26px;
  }
  .cb-layout2 h1 {
    font-size: 30px;
    line-height: 1.33333333;
  }
  h2 {
    font-size: 36px;
    line-height: 1.27777778;
  }
  h3 {
    font-size: 28px;
    line-height: 1.35714286;
  }
  h4 {
    font-size: 22px;
    line-height: 1.45454545;
  }
  h5 {
    font-size: 18px;
    line-height: 1.55555556;
  }
  .loud {
    font-size: 22px;
    line-height: 1.45454545;
  }
}
.section--two .area .pure.slim .part:last-child {
  padding-top: 10px;
}
.section--two .area .seam {
  margin-top: 16px;
  margin-bottom: 16px;
}
/*# sourceMappingURL=./screen-small.css.map */